<template>
	<section class="py-6 wrapper over">
		<h3 class="text-center" :class="headerClass">See what others are&nbsp;saying</h3>
		<div class="pricing__testimonials b5:flex gutters b5:flex--2-cols b9:flex--3-cols mt-2 py-4">
			<c-testimonial
				v-for="review of reviews"
				:key="review.Id"
				:title="review.Title"
				:review="review.Comments"
				:stars="review.Rating"
				:name="review.Name"
				:max-height="180"
			/>
		</div>
	</section>
</template>

<script>
	import CTestimonial from 'src/components/Testimonial.vue';
	import componentMountedEvent from '@cognitoforms/shared-components/src/mixins/content-component-mounted-event';
	import { getReviews } from '@cognitoforms/api/services/review-service';

	export default {
		name: 'CReviews',
		components: {
			CTestimonial
		},
		mixins: [componentMountedEvent],
		props: {
			tag: { type: String, default: 'home' },
			headerClass: { type: String, default: 'sz-6' },
			amount: { type: Number, default: 3 }
		},
		data() {
			return {
				reviews: []
			};
		},
		async mounted() {
			let reviewResults = [];
			if (process.env.NODE_ENV === 'development')
				reviewResults = (await import ('./dev-reviews')).default;
			else
				reviewResults = await getReviews(this.tag);
			this.reviews = reviewResults.slice(0, this.amount);
		}
	};
</script>
