<template>
	<div class="testimonial py-3 px-4">
		<div class="testimonial__top">
			<inline svg="complex-icons/quote" class="matins" />
			<div
				class="testimonial__top-stars"
				:aria-description="`Rated ${stars} out of 5`"
			>
				<inline
					v-for="index in 5"
					:key="index"
					svg="star"
					class="testimonial__star icon--fill pudding"
					:class="getClass(index)"
				/>
			</div>
		</div>
		<div class="testimonial__title matins heavy sz-4">
			{{ title }}
		</div>
		<div class="testimonial__review mt-3" :class="{truncate: shouldTruncate, expanded: isExpanded}">
			<div ref="review" class="testimonial__review-body" :style="{'max-height': shouldTruncate ? maxHeight + 'px' : 'none'}">
				<span>{{ review }}</span>
			</div>
		</div>
		<div class="flex flex--align mt-3">
			<div class="testimonial__byline sz-2 mr-1">&mdash;&nbsp;{{ name }}</div>
			<div v-if="shouldTruncate" class="testimonial__more text-right sz-2 p-1">
				<button v-if="!isExpanded" class="button--no-style deep underline" @click="handleMoreClick">More</button>
				<button v-if="isExpanded" class="button--no-style deep underline" @click="handleLessClick">Less</button>
			</div>
		</div>
	</div>
</template>

<script>
	import componentMountedEvent from '@cognitoforms/shared-components/src/mixins/content-component-mounted-event';

	export default {
		name: 'CTestimonial',
		mixins: [componentMountedEvent],
		props: {
			title: {
				type: String,
				required: true
			},
			name: {
				type: String,
				required: true
			},
			review: {
				type: String,
				required: true
			},
			stars: {
				type: Number,
				required: true
			},
			maxHeight: {
				type: Number
			}
		},
		data() {
			return {
				shouldTruncate: false,
				isExpanded: false
			};
		},
		mounted() {
			if (this.maxHeight !== undefined) {
				const reviewHeight = this.getReviewHeight();
				if (reviewHeight > this.maxHeight + 30) {
					this.shouldTruncate = true;
				}
			}
		},
		methods: {
			getClass(index) {
				const score = this.stars.toString().split('.');

				// Does the score have a decimal value?
				// Is the index one digit greater than the whole number?
				if (score[1] && index === parseInt(score[0]) + 1)
					return 'testimonial__star--half';
				else if (index <= this.stars)
					return 'pudding';
				else
					return 'brady';
			},
			getReviewHeight() {
				return this.$refs['review'].clientHeight;
			},
			handleMoreClick(e) {
				this.isExpanded = true;
			},
			handleLessClick(e) {
				this.isExpanded = false;
			}
		}
	};
</script>

<style lang="scss">
.testimonial {

	.i-quote {
		width: 4rem;
		height: 4rem;
	}

	.testimonial__star {
		width: 1.25rem;
		height: 1.25rem;
		margin-left: .1rem;

		&--half {
			color: $brady;

			.i-half {
				color: $pudding;
			}
		}
	}

	&__top {
		display: flex;
		justify-content: space-between;

		&-stars {
			display: flex;
		}
	}

	&__title {
		font-family: $header;
	}

	&__review {

		&.truncate {
			position: relative;

			.testimonial__review-body {
				overflow: hidden;
				transition: max-height 1s;

				span {
					white-space: pre-line;
				}
			}

			&::after {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				top: -1em;
				z-index: $z1;
				background: linear-gradient(180deg, rgba(255, 255, 255, 0%) 60%, white 100%);
				content: '';
				transition: opacity 1s;
			}

			&.expanded {

				.testimonial__review-body {
					max-height: 350px !important;
				}

				&::after {
					opacity: 0;
				}
			}
		}
	}

	&__byline {
		overflow-wrap: anywhere;
	}

	&__more {
		position: relative;
		z-index: $z2;
		margin-left: auto;
	}
}
</style>